.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.main-herader {
  position: fixed !important;
  z-index: 1000;
  top: 0;
  left: 0;
  right: 0;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.bolgCon {
  display: flex !important;
  flex-wrap: wrap;
}

.TitleName {
  font-weight: bold;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25px;
}

.splQuotes {
  display: flex;
  justify-content: center;
  align-items: center;
}

.splQuoteText {
  height: 100px;
  align-items: center;
  display: flex;
  text-align: center;
}

.Property-image {
  height: 185px !important;
  width: 350px !important;
}











.img1 {
  height: 85px !important;
  width: 125px !important;
}

.navbacollapse {
  display: block;
}

.fa-times:before {
  content: "\f0c9" !important;
}



.nav.navbar .navbar-brand img.logo {
  height: 40px !important;
  width: 150px !important;
}

@media (min-width:260px) and (max-width:768px) {

  .a,
  .p,
  h6 {
    font-size: 15px !important;
  }

  h1,
  h2,
  h3 {
    font-size: 25px !important;
    font-weight: 900 !important;
  }


}

/* .logo{
  width: 40px;
} */

.download-brocher #abc {
  padding: 15px;
  text-decoration: none;
  cursor: pointer;
  background: #00b050;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 5px #444, 0px 0px 5px #222, inset 0px 0px 5px #222, inset 0px 0px 5px #444;
  color: white;
  font-size: 16px;
  display: flex;
  width: 200px;
}

.abc2 {
  display: flex;
  justify-content: end;

}

@media (min-width:0px) and (max-width:560px) {
  .slider-area-two .carousel-fade .carousel-inner .active .carousel-caption {
    opacity: 0.9;
    display: block !important;
  }

  .carousel-caption h3,
  .carousel-caption p,
  .carousel-caption .btn {
    display: block;
  }

  a.btn-default {
    background: transparent;
    color: #Fff;
    font-weight: 400;
    font-size: 13px;
    line-height: 15px;
    margin-right: 10px;
    text-align: center;
    padding: 17px 30px;
    white-space: nowrap;
    letter-spacing: 1px;
    display: inline-block;
    border: 1px solid #FFF;
    text-transform: uppercase;
    width: '150px' !important;
  }

  .abc3 {

    display: 'flex';
    justify-content: center;
    align-items: center;
  }
}

.iconimage {
  height: 40px;
  width: 50px;

  /* background-color: #00b050; */
}

.AmititiesContainer {
  height: 100px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.AmititiesContainer.active {
  background-color: #f4f4f4;
  border: 0px;
}

.property-box .property-content p {
  color: black !important;
}